<template>
  <div>
    <div id="container" :style="'height:' + pmHeight + 'px;'"></div>
    <div class="search-box" v-if="!this.showPopup">
      <div class="search-box-one" style="margin-top:15px;margin-bottom: 15px;;" @click="showList">
        <van-icon class="search-icon" name="search" />
        <div
          class="input-sty"
        >输入途经点关键字查询</div>
      </div>
      <div style="width:90%;margin-left:5%;border-radius: 10px;margin-top: 5px;padding: 5px 10px;" >
        <!-- <div style="width:95%;margin-left:5%; height:1px;background:#f2f2f2;margin-top: 5px;"></div> -->
        <div style="display:flex;margin-top: 5px;" v-if="pointType!=3">
          <div><van-icon name="location-o" style="font-size:14px;margin-right: 3px;"/>{{pointType == 1 ? '起点：' : (pointType == 2 ? '终点：' : '途经点：')}}</div>
          <div>{{pointInfo.name}}</div>
        </div>
        <div style="display:flex; margin-top: 5px;flex-direction: column;" v-if="tjd.length!=0&&pointType==3">
          <div class="simple-page">
            <Container @drop="onDrop">
              <Draggable v-for="(ite,index) in tjd" :key="index">
                <div style="background: #FAFAFB;margin-top:10px;padding: 5px 10px;border-radius: 5px;">
                  <div style="display: flex;align-items: center;justify-content: space-between;">
                    <div>
                      <van-icon name="location-o" style="font-size:14px;margin-right: 3px;"/>
                    {{pointType == 1 ? '起点：' : (pointType == 2 ? '终点：' : '途经点' + (index+1)+ '：')}}{{ite.name}} 
                    </div>
                    <span>
                      <img style="width:15px;margin-left:20px;" src="./comm/tuodong-icon.png" alt="">
                    </span>
                  </div>
              </div>
              </Draggable>
            </Container>
          </div>


          <!-- <div v-for="(ite, index) in tjd" :key="index" >
            <div><van-icon name="location-o" style="font-size:14px;margin-right: 3px;"/>{{pointType == 1 ? '起点：' : (pointType == 2 ? '终点：' : '途经点' + (index+1)+ '：')}}{{ite.name}} <span><img style="width:15px;margin-left:20px;" src="./comm/sxthwz.png" alt=""></span></div>
          </div> -->
          
        </div>
        <!-- <div style="width:95%;margin-left:5%; height:1px;background:#f2f2f2;margin-top: 5px;"></div>
        <div style="display:flex;margin-top: 9px;">
          <div>距离最短：全程{{((drevingInfo.routes[0].distance) / 1000).toFixed(1)}}公里，确定信息无误后，请点击下一步</div>
        </div> -->
      </div>
      <div class="next"  @click="surePoint">
        确定
      </div>
    </div>
    <van-popup v-model="showPopup" round  position="bottom" :style="{ height: '400px' }">
      <div style="position: relative;">
        <div class="search-box1">
          <div class="search-box-one">
            <van-icon class="search-icon" name="search" />
            <input
              class="input-sty"
              type="text"
              v-model="keyword"
              @input="inputKey"
              placeholder="输入途经点关键字"
            />
          </div>
        </div>
        <div style="width:90%;height:400px;margin-left:5%;padding-top: 60px;overflow-y: scroll;" class="gdt">
          <div v-for="(item,index) in searchList" :key="index" class="address-item" @click="selPoint(item)">
            <div style="width:78%;display: flex;align-items: center;">
              <van-icon name="location-o" style="font-size:18px;"/>
              <div style="margin-left:5px;width:100%;">
                  <div style="font-size: 15px;font-weight:700;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;-o-text-overflow:ellipsis;">
                  {{item.name}}
                  </div>
                  <div style="font-size:12px;margin-top:2px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;-o-text-overflow:ellipsis;">
                    {{item.district}}{{item.address}}
                  </div>
              </div>
            </div>
            <div>
              路线
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- <div v-for="item in searchList" :key="item" @click="selPoint(item)">{{item.name}}</div> -->
  </div>
</template>
<script>
  import Bus from "@/utils/bus";
  import { Container, Draggable } from "vue-smooth-dnd";
  const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  console.log(removedIndex, addedIndex, payload);
  if (removedIndex === null && addedIndex === null) return arr;
 
  const result = [...arr];
  let itemToAdd = payload;
 
  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }
 
  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }
 
  return result;
};
const generateItems = (count, creator) => {
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push(creator(i));
  }
  return result;
};
let timers = null;
import { Icon, Popup } from "vant";
import AMap from "AMap"; // 引入高德地图
var driving;
// 创建一个 Marker 实例：
var marker
export default {
  components: {
    Container, Draggable,
    VanIcon: Icon,
    VanPopup: Popup,
  },
  data() {
    return {
      items: generateItems(50, (i) => ({ id: i, data: "Draggable " + i })),
      canEdit:true,
      map: null,
      autoComplete: null,
      keyword: null,
      searchList: [],
      pmHeight: 500,
      showPopup: true,
      pointInfo: null,
      drevingInfo: null,
      pointType: 1,
      tjd: [],
      addressInfo:null
    };
  },
  mounted() {
    this.pointType = this.$route.query.type
    this.addressInfo = JSON.parse(this.$route.query.info)
    this.tjd = JSON.parse(this.$route.query.info).tjdAddress == null ? [] : JSON.parse(this.$route.query.info).tjdAddress
    console.log(this.tjd,'tjd')
    this.pmHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    // this.initMap();
    this.initgHMap()
  },
  methods: {
    onDrop(dropResult) {
      this.tjd = applyDrag(this.tjd, dropResult);
      this.initgHMap()
    },
    surePoint() {
      Bus.$emit('my_events',JSON.stringify({
        type: this.pointType,
        info: this.pointType == 3 ? this.tjd : this.pointInfo
      }))
      this.$router.back()
    },
    showList() {
      this.showPopup = true
    },
    inputKey() {
      this.search();
    },
    selPoint(e) {
      console.log(e, 123);
      var _this = this;
      console.log(this.tjd,'this.tjd')
      _this.pointInfo = e
      if(this.pointType == 3) {
        this.tjd.push(e)
        this.initgHMap()
      } else {
       console.log(this.tjd,'this.tjd')
      _this.pointInfo = e
      this.pmHeight =
      (window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight) - 198;
      _this.keyword = ""
      _this.searchList = []
      if(marker) {
        _this.map.remove(marker);
      }
      const dom = document.getElementById("container");
      this.map = new AMap.Map(dom, {
        resizeEnable: true,
        zoom: 16,
        center: [e.location.lng, e.location.lat],
        showLabel: true, //不显示地图文字标记, 终于可以配置了TAT
      });
      // 创建一个 Marker 实例：
      marker = new AMap.Marker({
          position: new AMap.LngLat(e.location.lng, e.location.lat),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          offset: new AMap.Pixel(-10, -10),
          title: '北京',
          // icon: icons
          // offset:new AMap.Pixel(-10,-20)
      });

      // 将创建的点标记添加到已有的地图实例：
      _this.map.add(marker);
      
      }
      _this.showPopup = false

    },
    search() {
      var _this = this;
      clearTimeout(timers)
      timers = setTimeout(() => {
          this.autoComplete.search(this.keyword, function (status, result) {
          // 搜索成功时，result即是对应的匹配数据
          console.log(status);
          console.log(result);
          _this.searchList = result.tips;
        });
      }, 300);
    },
    initgHMap() {
      var _this = this;
      const dom = document.getElementById("container");
      this.map = new AMap.Map(dom, {
        resizeEnable: true,
        zoom: 15,
        center: [120.246232, 29.986425],
        showLabel: true, //不显示地图文字标记, 终于可以配置了TAT
      });
      AMap.plugin("AMap.AutoComplete", function () {
        var autoOptions = {
          //city 限定城市，默认全国
          city: "全国",
        };
        // 实例化AutoComplete
        _this.autoComplete = new AMap.Autocomplete(autoOptions);
      });
      // // JSAPI 2.0 输入提示插件名称由 AMap.Autocomplete 变更为 AMap.AutoComplete 啦！
      // AMap.plugin('AMap.ToolBar',function(){//异步加载插件
      //     var toolbar = new AMap.ToolBar();
      //     _this.map.addControl(toolbar);
      // });
      if (driving) {
        driving.clear();
      }
      if(_this.addressInfo.startAddress != null && _this.addressInfo.endAddress != null) {


      AMap.plugin("AMap.Driving", function () {
        driving = new AMap.Driving({
          // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
          policy: AMap.DrivingPolicy.LEAST_DISTANCE,
          // map 指定将路线规划方案绘制到对应的AMap.Map对象上
          map: _this.map,
          // panel 指定将结构化的路线详情数据显示的对应的DOM上，传入值需是DOM的ID
          // panel: 'container'
        });
        
        var startLngLat = [_this.addressInfo.startAddress.location[0], _this.addressInfo.startAddress.location[1]];
        var endLngLat = [_this.addressInfo.endAddress.location[0], _this.addressInfo.endAddress.location[1]];
        let ls = []
        console.log(_this.tjd,'_this.tjd');
        if(_this.tjd.length!=0) {
          for(let i in _this.tjd) {
            if(_this.tjd[i].location!=undefined && _this.tjd[i].location!="" && _this.tjd[i].location!=null) {
              if(_this.tjd[i].location.length == 2) {
                ls.push(new AMap.LngLat(_this.tjd[i].location[0], _this.tjd[i].location[1]))
              } else {
                ls.push(new AMap.LngLat(_this.tjd[i].location.lng, _this.tjd[i].location.lat))
              }
              
            }
          }
        }
        
        var opts = {
          waypoints: ls
        }

        console.log(startLngLat);
        console.log(endLngLat);
        driving.search(startLngLat, endLngLat,  opts, function (status, result) {
          // 未出错时，result即是对应的路线规划方案
          console.log(status);
          console.log(result);
          _this.drevingInfo = result;
        });
        // _this.showPopup = false
      });
    }
    },
    initMap() {
      var _this = this;
      const dom = document.getElementById("container");
      this.map = new AMap.Map(dom, {
        resizeEnable: true,
        zoom: 15,
        center: [120.246232, 29.986425],
        showLabel: true, //不显示地图文字标记, 终于可以配置了TAT
      });
      // // JSAPI 2.0 输入提示插件名称由 AMap.Autocomplete 变更为 AMap.AutoComplete 啦！
      // AMap.plugin('AMap.ToolBar',function(){//异步加载插件
      //     var toolbar = new AMap.ToolBar();
      //     _this.map.addControl(toolbar);
      // });

      AMap.plugin("AMap.AutoComplete", function () {
        var autoOptions = {
          //city 限定城市，默认全国
          city: "全国",
        };
        // 实例化AutoComplete
        _this.autoComplete = new AMap.Autocomplete(autoOptions);
      });
    },
  },
};
</script>
<style  scoped>
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 600px;
}
.search-box1 {
  position: absolute;
  left: 0;
  top:0;
  width: 100%;
  display: flex;
  background: #fff;
  height: 60px;
  align-items: center;
  z-index: 99999999999;
}
.search-box {
  position: fixed;
  left: 0;
  bottom: 0px;
  width: 100%;
  display: flex;
  background: #fff;
  min-height: 60px;
  flex-direction: column;
  /* align-items: center; */
  z-index: 99999999999;
}
.search-box-one {
  position: relative;
  width: 90%;
  margin-left: 5%;
  height: 40px;
  border: 2px solid #f2f2f2;
  border-radius: 40px;
  display: flex;
  align-items: center;
}
.input-sty {
  width: 80%;
  margin-left: 2%;
  height: 35px;
  line-height: 35px;
  border: none;
}
.search-icon {
  font-size: 20px;
  margin-left: 10px;
}
.address-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #f2f2f2;
}
.gdt::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}
.next {
  width:90%;
  margin-left: 5%;
  height: 39px;
  background: #2D83E8;
  margin-top: 25px;;
  border-radius: 40px;
  text-align: center;
  line-height: 39px;
  color: #fff;
  font-size: 16px;;
  margin-bottom: 25px;;
}
.draggable-item {
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
  background-color: #fff;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 2px;
  margin-top: 2px;
  cursor: default;
  user-select: none;
}
</style>